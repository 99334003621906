<template>
  <b-form-group
    label="Marka"
    label-for="brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="brand"
        v-model="dataItem.id_com_brand"
        label="title"
        :options="brands"
        :reduce="brand => brand.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'SaleDiscoverBrand',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingMobileAppSaleDiscover/getItemData']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
  },
}
</script>

<style scoped></style>
