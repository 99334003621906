<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <sale-discover-title />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <sale-discover-brand />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <sale-discover-content />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import SaleDiscoverTitle from '@/views/Marketing/Mobile_app/Sale_discover/Elements/titleCard.vue'
import SaleDiscoverContent from '@/views/Marketing/Mobile_app/Sale_discover/Elements/contentCard.vue'
import SaleDiscoverBrand from '@/views/Marketing/Mobile_app/Sale_discover/Elements/brandCard.vue'

export default {
  name: 'SaleDiscoverItemForm',
  components: {
    SaleDiscoverBrand,
    SaleDiscoverContent,
    SaleDiscoverTitle,
    BRow,
    BCol,
  },
}
</script>
