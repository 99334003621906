<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel</span>
        </template>
        <validation-observer ref="simpleRules">
          <b-card title="Düzenle">
            <sale-discover-item-form />
          </b-card>
          <sale-discover-image-card />
          <b-button
            :disabled="dataItem.submitStatus"
            variant="primary"
            @click="submitData"
          >
            <feather-icon icon="SaveIcon" />
            Kaydet
          </b-button>
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>Alt Öğeler</span>
        </template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Alt Öğeler</b-card-title>
            <b-button
              variant="primary"
              :to="'/marketing/mobile-app/sales-blog/add-line/'+dataItem.id"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Oluştur
            </b-button>
          </b-card-header>
          <template v-if="dataItem.items.length > 0 ">
            <b-table
              responsive="sm"
              :fields="fields"
              :items="dataItem.items"
              striped
              hover
            >
              <template #cell(created)="data">
                {{ moment(data.item.created).format('DD.MM.YYYY') }}
              </template>
              <template #cell(control)="data">
                <b-dropdown
                  :split-to="'/marketing/mobile-app/sales-blog/edit-line/' + data.item.id"
                  text="Görüntüle"
                  variant="outline-primary"
                  size="sm"
                  split
                >
                  <b-dropdown-item :to="'/marketing/mobile-app/sales-blog/edit-line/' + data.item.id">
                    Güncelle
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeData(data.item.id)">
                    Sil
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </template>
          <template v-else>
            <b-alert
              show
              variant="info"
              class="m-2"
            >
              <div class="alert-body text-center">
                Kayıtlı veri bulunmuyor.
              </div>
            </b-alert>
          </template>

        </b-card>
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>
import {
  BCard, BButton, BTabs, BTab, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BTable, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import SaleDiscoverItemForm from '@/views/Marketing/Mobile_app/Sale_discover/itemForm.vue'
import SaleDiscoverImageCard from '@/views/Marketing/Mobile_app/Sale_discover/Elements/Image.vue'

export default {
  name: 'Add',
  components: {
    BAlert,
    BTable,
    BDropdownItem,
    BDropdown,
    BTab,
    BTabs,
    SaleDiscoverImageCard,
    SaleDiscoverItemForm,
    BCard,
    ValidationObserver,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'Başlık',
          thClass: 'align-middle',
        },
        {
          key: 'created',
          label: 'Tarih',
          thClass: 'align-middle',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingMobileAppSaleDiscover/getItemData']
    },
    saveData() {
      return this.$store.getters['marketingMobileAppSaleDiscover/getItemDataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    localize('tr')
    this.getData()
    this.$store.commit('marketingMobileAppSaleDiscover/RESET_ITEM_DATA')
  },
  methods: {
    getData() {
      this.$store.dispatch('marketingMobileAppSaleDiscover/itemData', this.$route.params.id)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('marketingMobileAppSaleDiscover/saveData', this.dataItem)
        }
      })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('marketingMobileAppSaleDiscoverLines/deleteData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getData()
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
